.btn {
  border-width: 2px;
}
img,
.card-wrap,
.card-wrapper,
.video-wrapper,
.mbr-figure iframe,
.google-map iframe,
.slide-content,
.plan,
.card,
.item-wrapper {
  border-radius: 2rem !important;
}
.video-wrapper {
  overflow: hidden;
}
body {
  background-color: #ffeeef;
}
body {
  font-family: Inter Tight;
}
.display-1 {
  font-family: 'Inter Tight', sans-serif;
  font-size: 5rem;
  line-height: 1;
}
.display-1 > .mbr-iconfont {
  font-size: 6.25rem;
}
.display-2 {
  font-family: 'Inter Tight', sans-serif;
  font-size: 4rem;
  line-height: 1;
}
.display-2 > .mbr-iconfont {
  font-size: 5rem;
}
.display-4 {
  font-family: 'Inter Tight', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
}
.display-4 > .mbr-iconfont {
  font-size: 1.5rem;
}
.display-5 {
  font-family: 'Inter Tight', sans-serif;
  font-size: 2.5rem;
  line-height: 1.5;
}
.display-5 > .mbr-iconfont {
  font-size: 3.125rem;
}
.display-7 {
  font-family: 'Raleway', sans-serif;
  font-size: 1.4rem;
  line-height: 1.3;
}
.display-7 > .mbr-iconfont {
  font-size: 1.75rem;
}
/* ---- Fluid typography for mobile devices ---- */
/* 1.4 - font scale ratio ( bootstrap == 1.42857 ) */
/* 100vw - current viewport width */
/* (48 - 20)  48 == 48rem == 768px, 20 == 20rem == 320px(minimal supported viewport) */
/* 0.65 - min scale variable, may vary */
@media (max-width: 992px) {
  .display-1 {
    font-size: 4rem;
  }
}
@media (max-width: 768px) {
  .display-1 {
    font-size: 3.5rem;
    font-size: calc( 2.4rem + (5 - 2.4) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.1 * (2.4rem + (5 - 2.4) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-2 {
    font-size: 3.2rem;
    font-size: calc( 2.05rem + (4 - 2.05) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.3 * (2.05rem + (4 - 2.05) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-4 {
    font-size: 0.96rem;
    font-size: calc( 1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-5 {
    font-size: 2rem;
    font-size: calc( 1.525rem + (2.5 - 1.525) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.525rem + (2.5 - 1.525) * ((100vw - 20rem) / (48 - 20))));
  }
  .display-7 {
    font-size: 1.12rem;
    font-size: calc( 1.14rem + (1.4 - 1.14) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.14rem + (1.4 - 1.14) * ((100vw - 20rem) / (48 - 20))));
  }
}
@media (min-width: 992px) and (max-width: 1400px) {
  .display-1 {
    font-size: 3.5rem;
    font-size: calc( 2.4rem + (5 - 2.4) * ((100vw - 62rem) / (87 - 62)));
    line-height: calc( 1.1 * (2.4rem + (5 - 2.4) * ((100vw - 62rem) / (87 - 62))));
  }
  .display-2 {
    font-size: 3.2rem;
    font-size: calc( 2.05rem + (4 - 2.05) * ((100vw - 62rem) / (87 - 62)));
    line-height: calc( 1.3 * (2.05rem + (4 - 2.05) * ((100vw - 62rem) / (87 - 62))));
  }
  .display-4 {
    font-size: 0.96rem;
    font-size: calc( 1.07rem + (1.2 - 1.07) * ((100vw - 62rem) / (87 - 62)));
    line-height: calc( 1.4 * (1.07rem + (1.2 - 1.07) * ((100vw - 62rem) / (87 - 62))));
  }
  .display-5 {
    font-size: 2rem;
    font-size: calc( 1.525rem + (2.5 - 1.525) * ((100vw - 62rem) / (87 - 62)));
    line-height: calc( 1.4 * (1.525rem + (2.5 - 1.525) * ((100vw - 62rem) / (87 - 62))));
  }
  .display-7 {
    font-size: 1.12rem;
    font-size: calc( 1.14rem + (1.4 - 1.14) * ((100vw - 62rem) / (87 - 62)));
    line-height: calc( 1.4 * (1.14rem + (1.4 - 1.14) * ((100vw - 62rem) / (87 - 62))));
  }
}
/* Buttons */
.btn {
  padding: 1.25rem 2rem;
  border-radius: 4px;
}
@media (max-width: 767px) {
  .btn {
    padding: 0.75rem 1.5rem;
  }
}
.btn-sm {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.btn-md {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}
.btn-lg {
  padding: 1.25rem 2rem;
  border-radius: 4px;
}
.bg-primary {
  background-color: #8ac926 !important;
}
.bg-success {
  background-color: #20ac6b !important;
}
.bg-info {
  background-color: #0aa3c2 !important;
}
.bg-warning {
  background-color: #cc9900 !important;
}
.bg-danger {
  background-color: #ae1e2c !important;
}
.btn-primary,
.btn-primary:active {
  background-color: #8ac926 !important;
  border-color: #8ac926 !important;
  color: #ffffff !important;
  box-shadow: none;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary.active {
  color: inherit;
  background-color: #a2dc46 !important;
  border-color: #a2dc46 !important;
  box-shadow: none;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff !important;
  background-color: #a2dc46 !important;
  border-color: #a2dc46 !important;
}
.btn-secondary,
.btn-secondary:active {
  background-color: #6a4c93 !important;
  border-color: #6a4c93 !important;
  color: #ffffff !important;
  box-shadow: none;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary.active {
  color: inherit;
  background-color: #8363af !important;
  border-color: #8363af !important;
  box-shadow: none;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #ffffff !important;
  background-color: #8363af !important;
  border-color: #8363af !important;
}
.btn-info,
.btn-info:active {
  background-color: #0aa3c2 !important;
  border-color: #0aa3c2 !important;
  color: #ffffff !important;
  box-shadow: none;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info.active {
  color: inherit;
  background-color: #0dccf3 !important;
  border-color: #0dccf3 !important;
  box-shadow: none;
}
.btn-info.disabled,
.btn-info:disabled {
  color: #ffffff !important;
  background-color: #0dccf3 !important;
  border-color: #0dccf3 !important;
}
.btn-success,
.btn-success:active {
  background-color: #20ac6b !important;
  border-color: #20ac6b !important;
  color: #ffffff !important;
  box-shadow: none;
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success.active {
  color: inherit;
  background-color: #28d786 !important;
  border-color: #28d786 !important;
  box-shadow: none;
}
.btn-success.disabled,
.btn-success:disabled {
  color: #ffffff !important;
  background-color: #28d786 !important;
  border-color: #28d786 !important;
}
.btn-warning,
.btn-warning:active {
  background-color: #cc9900 !important;
  border-color: #cc9900 !important;
  color: #ffffff !important;
  box-shadow: none;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning.active {
  color: inherit;
  background-color: #ffbf00 !important;
  border-color: #ffbf00 !important;
  box-shadow: none;
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #ffffff !important;
  background-color: #ffbf00 !important;
  border-color: #ffbf00 !important;
}
.btn-danger,
.btn-danger:active {
  background-color: #ae1e2c !important;
  border-color: #ae1e2c !important;
  color: #ffffff !important;
  box-shadow: none;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger.active {
  color: inherit;
  background-color: #da2637 !important;
  border-color: #da2637 !important;
  box-shadow: none;
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #ffffff !important;
  background-color: #da2637 !important;
  border-color: #da2637 !important;
}
.btn-white,
.btn-white:active {
  background-color: #eff0ec !important;
  border-color: #eff0ec !important;
  color: #757b62 !important;
  box-shadow: none;
}
.btn-white:hover,
.btn-white:focus,
.btn-white.focus,
.btn-white.active {
  color: inherit;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  box-shadow: none;
}
.btn-white.disabled,
.btn-white:disabled {
  color: #757b62 !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
.btn-black,
.btn-black:active {
  background-color: #232323 !important;
  border-color: #232323 !important;
  color: #ffffff !important;
  box-shadow: none;
}
.btn-black:hover,
.btn-black:focus,
.btn-black.focus,
.btn-black.active {
  color: inherit;
  background-color: #3d3d3d !important;
  border-color: #3d3d3d !important;
  box-shadow: none;
}
.btn-black.disabled,
.btn-black:disabled {
  color: #ffffff !important;
  background-color: #3d3d3d !important;
  border-color: #3d3d3d !important;
}
.btn-primary-outline,
.btn-primary-outline:active {
  background-color: transparent !important;
  border-color: #8ac926;
  color: #8ac926;
}
.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline.focus,
.btn-primary-outline.active {
  color: #588018 !important;
  background-color: transparent !important;
  border-color: #588018 !important;
  box-shadow: none !important;
}
.btn-primary-outline.disabled,
.btn-primary-outline:disabled {
  color: #ffffff !important;
  background-color: #8ac926 !important;
  border-color: #8ac926 !important;
}
.btn-secondary-outline,
.btn-secondary-outline:active {
  background-color: transparent !important;
  border-color: #6a4c93;
  color: #6a4c93;
}
.btn-secondary-outline:hover,
.btn-secondary-outline:focus,
.btn-secondary-outline.focus,
.btn-secondary-outline.active {
  color: #412e5a !important;
  background-color: transparent !important;
  border-color: #412e5a !important;
  box-shadow: none !important;
}
.btn-secondary-outline.disabled,
.btn-secondary-outline:disabled {
  color: #ffffff !important;
  background-color: #6a4c93 !important;
  border-color: #6a4c93 !important;
}
.btn-info-outline,
.btn-info-outline:active {
  background-color: transparent !important;
  border-color: #0aa3c2;
  color: #0aa3c2;
}
.btn-info-outline:hover,
.btn-info-outline:focus,
.btn-info-outline.focus,
.btn-info-outline.active {
  color: #065e70 !important;
  background-color: transparent !important;
  border-color: #065e70 !important;
  box-shadow: none !important;
}
.btn-info-outline.disabled,
.btn-info-outline:disabled {
  color: #ffffff !important;
  background-color: #0aa3c2 !important;
  border-color: #0aa3c2 !important;
}
.btn-success-outline,
.btn-success-outline:active {
  background-color: transparent !important;
  border-color: #20ac6b;
  color: #20ac6b;
}
.btn-success-outline:hover,
.btn-success-outline:focus,
.btn-success-outline.focus,
.btn-success-outline.active {
  color: #12633e !important;
  background-color: transparent !important;
  border-color: #12633e !important;
  box-shadow: none !important;
}
.btn-success-outline.disabled,
.btn-success-outline:disabled {
  color: #ffffff !important;
  background-color: #20ac6b !important;
  border-color: #20ac6b !important;
}
.btn-warning-outline,
.btn-warning-outline:active {
  background-color: transparent !important;
  border-color: #cc9900;
  color: #cc9900;
}
.btn-warning-outline:hover,
.btn-warning-outline:focus,
.btn-warning-outline.focus,
.btn-warning-outline.active {
  color: #755800 !important;
  background-color: transparent !important;
  border-color: #755800 !important;
  box-shadow: none !important;
}
.btn-warning-outline.disabled,
.btn-warning-outline:disabled {
  color: #ffffff !important;
  background-color: #cc9900 !important;
  border-color: #cc9900 !important;
}
.btn-danger-outline,
.btn-danger-outline:active {
  background-color: transparent !important;
  border-color: #ae1e2c;
  color: #ae1e2c;
}
.btn-danger-outline:hover,
.btn-danger-outline:focus,
.btn-danger-outline.focus,
.btn-danger-outline.active {
  color: #641119 !important;
  background-color: transparent !important;
  border-color: #641119 !important;
  box-shadow: none !important;
}
.btn-danger-outline.disabled,
.btn-danger-outline:disabled {
  color: #ffffff !important;
  background-color: #ae1e2c !important;
  border-color: #ae1e2c !important;
}
.btn-black-outline,
.btn-black-outline:active {
  background-color: transparent !important;
  border-color: #232323;
  color: #232323;
}
.btn-black-outline:hover,
.btn-black-outline:focus,
.btn-black-outline.focus,
.btn-black-outline.active {
  color: #000000 !important;
  background-color: transparent !important;
  border-color: #000000 !important;
  box-shadow: none !important;
}
.btn-black-outline.disabled,
.btn-black-outline:disabled {
  color: #ffffff !important;
  background-color: #232323 !important;
  border-color: #232323 !important;
}
.btn-white-outline,
.btn-white-outline:active {
  background-color: transparent !important;
  border-color: #fafafa;
  color: #fafafa;
}
.btn-white-outline:hover,
.btn-white-outline:focus,
.btn-white-outline.focus,
.btn-white-outline.active {
  color: #cfcfcf !important;
  background-color: transparent !important;
  border-color: #cfcfcf !important;
  box-shadow: none !important;
}
.btn-white-outline.disabled,
.btn-white-outline:disabled {
  color: #7a7a7a !important;
  background-color: #fafafa !important;
  border-color: #fafafa !important;
}
.text-primary {
  color: #8ac926 !important;
}
.text-secondary {
  color: #6a4c93 !important;
}
.text-success {
  color: #20ac6b !important;
}
.text-info {
  color: #0aa3c2 !important;
}
.text-warning {
  color: #cc9900 !important;
}
.text-danger {
  color: #ae1e2c !important;
}
.text-white {
  color: #fafafa !important;
}
.text-black {
  color: #232323 !important;
}
a.text-primary:hover,
a.text-primary:focus,
a.text-primary.active {
  color: #4f7316 !important;
}
a.text-secondary:hover,
a.text-secondary:focus,
a.text-secondary.active {
  color: #3a2950 !important;
}
a.text-success:hover,
a.text-success:focus,
a.text-success.active {
  color: #105636 !important;
}
a.text-info:hover,
a.text-info:focus,
a.text-info.active {
  color: #055161 !important;
}
a.text-warning:hover,
a.text-warning:focus,
a.text-warning.active {
  color: #664d00 !important;
}
a.text-danger:hover,
a.text-danger:focus,
a.text-danger.active {
  color: #570f16 !important;
}
a.text-white:hover,
a.text-white:focus,
a.text-white.active {
  color: #c7c7c7 !important;
}
a.text-black:hover,
a.text-black:focus,
a.text-black.active {
  color: #000000 !important;
}
a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption) {
  position: relative;
  background-image: transparent;
  background-size: 10000px 2px;
  background-repeat: no-repeat;
  background-position: 0px 1.2em;
  background-position: -10000px 1.2em;
}
a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption):hover {
  transition: background-position 2s ease-in-out;
  background-image: linear-gradient(currentColor 50%, currentColor 50%);
  background-position: 0px 1.2em;
}
.nav-tabs .nav-link.active {
  color: #8ac926;
}
.nav-tabs .nav-link:not(.active) {
  color: #232323;
}
.alert-success {
  background-color: #70c770;
}
.alert-info {
  background-color: #0aa3c2;
}
.alert-warning {
  background-color: #cc9900;
}
.alert-danger {
  background-color: #ae1e2c;
}
.mbr-section-btn .btn:not(.btn-form) {
  border-radius: 100px;
}
.mbr-gallery-filter li a {
  border-radius: 100px !important;
}
.mbr-gallery-filter li.active .btn {
  background-color: #8ac926;
  border-color: #8ac926;
  color: #ffffff;
}
.mbr-gallery-filter li.active .btn:focus {
  box-shadow: none;
}
.nav-tabs .nav-link {
  border-radius: 100px !important;
}
a,
a:hover {
  color: #8ac926;
}
.mbr-plan-header.bg-primary .mbr-plan-subtitle,
.mbr-plan-header.bg-primary .mbr-plan-price-desc {
  color: #e3f4c7;
}
.mbr-plan-header.bg-success .mbr-plan-subtitle,
.mbr-plan-header.bg-success .mbr-plan-price-desc {
  color: #68e3aa;
}
.mbr-plan-header.bg-info .mbr-plan-subtitle,
.mbr-plan-header.bg-info .mbr-plan-price-desc {
  color: #55dbf6;
}
.mbr-plan-header.bg-warning .mbr-plan-subtitle,
.mbr-plan-header.bg-warning .mbr-plan-price-desc {
  color: #ffe599;
}
.mbr-plan-header.bg-danger .mbr-plan-subtitle,
.mbr-plan-header.bg-danger .mbr-plan-price-desc {
  color: #e56773;
}
/* Scroll to top button*/
.scrollToTop_wraper {
  display: none;
}
.form-control {
  font-family: 'Raleway', sans-serif;
  font-size: 1.4rem;
  line-height: 1.3;
  font-weight: 400;
  border-radius: 40px !important;
}
.form-control > .mbr-iconfont {
  font-size: 1.75rem;
}
.form-control:hover,
.form-control:focus {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
  border-color: #8ac926 !important;
}
.form-control:-webkit-input-placeholder {
  font-family: 'Raleway', sans-serif;
  font-size: 1.4rem;
  line-height: 1.3;
  font-weight: 400;
}
.form-control:-webkit-input-placeholder > .mbr-iconfont {
  font-size: 1.75rem;
}
blockquote {
  border-color: #8ac926;
}
/* Forms */
.mbr-form .input-group-btn .btn {
  border-radius: 100px !important;
}
.mbr-form .input-group-btn .btn:hover {
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.mbr-form .input-group-btn button[type="submit"] {
  border-radius: 100px !important;
  padding: 1rem 3rem;
}
.mbr-form .input-group-btn button[type="submit"]:hover {
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.2);
}
.jq-selectbox li:hover,
.jq-selectbox li.selected {
  background-color: #8ac926;
  color: #ffffff;
}
.jq-number__spin {
  transition: 0.25s ease;
}
.jq-number__spin:hover {
  border-color: #8ac926;
}
.jq-selectbox .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:after,
.jq-number__spin.plus:after {
  transition: 0.4s;
  border-top-color: #232323;
  border-bottom-color: #232323;
}
.jq-selectbox:hover .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:hover:after,
.jq-number__spin.plus:hover:after {
  border-top-color: #8ac926;
  border-bottom-color: #8ac926;
}
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  color: #000000 !important;
  background-color: #8ac926 !important;
  box-shadow: none !important;
}
.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #ffffff !important;
  background: #6a4c93 !important;
  box-shadow: none !important;
}
.lazy-bg {
  background-image: none !important;
}
.lazy-placeholder:not(section),
.lazy-none {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: auto;
}
iframe.lazy-placeholder,
.lazy-placeholder:after {
  content: '';
  position: absolute;
  width: 200px;
  height: 200px;
  background: transparent no-repeat center;
  background-size: contain;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='32' height='32' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg' stroke='%238ac926' %3e%3cg fill='none' fill-rule='evenodd'%3e%3cg transform='translate(16 16)' stroke-width='2'%3e%3ccircle stroke-opacity='.5' cx='16' cy='16' r='16'/%3e%3cpath d='M32 16c0-9.94-8.06-16-16-16'%3e%3canimateTransform attributeName='transform' type='rotate' from='0 16 16' to='360 16 16' dur='1s' repeatCount='indefinite'/%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}
section.lazy-placeholder:after {
  opacity: 0.5;
}
body {
  overflow-x: hidden;
}
a {
  transition: color 0.6s;
}
@media (max-width: 1400px) {
  .container {
    max-width: 100%;
  }
}
@media (max-width: 1400px) and (min-width: 768px) {
  .container {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 1400px) and (min-width: 768px) {
  .container-fluid {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 767px) {
  .container-fluid {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.cid-u8iUylNUta {
  z-index: 1000;
  width: 100%;
  position: relative;
}
.cid-u8iUylNUta .dropdown-item:before {
  font-family: Moririse2 !important;
  content: "\e966";
  display: inline-block;
  width: 0;
  position: absolute;
  left: 1rem;
  top: 0.5rem;
  margin-right: 0.5rem;
  line-height: 1;
  font-size: inherit;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
  transform: scale(0, 1);
  transition: all 0.25s ease-in-out;
}
@media (max-width: 767px) {
  .cid-u8iUylNUta .navbar-toggler {
    transform: scale(0.8);
  }
}
.cid-u8iUylNUta .navbar-brand {
  flex-shrink: 0;
  align-items: center;
  margin-right: 0;
  padding: 10px 0;
  transition: all 0.3s;
  word-break: break-word;
  z-index: 1;
}
.cid-u8iUylNUta .navbar-brand img {
  max-width: 100%;
  max-height: 100%;
}
.cid-u8iUylNUta .navbar-brand .navbar-caption {
  line-height: inherit !important;
}
.cid-u8iUylNUta .navbar-brand .navbar-logo a {
  outline: none;
}
.cid-u8iUylNUta .navbar-nav {
  margin: auto;
  margin-left: 0;
  margin-left: auto;
  margin-right: 0;
}
.cid-u8iUylNUta .navbar-nav .nav-item {
  padding: 0 !important;
  transition: 0.3s all !important;
}
.cid-u8iUylNUta .navbar-nav .nav-item .nav-link {
  padding: 16px !important;
  margin: 0 !important;
  border-radius: 1rem !important;
  transition: 0.3s all !important;
}
.cid-u8iUylNUta .navbar-nav .nav-item .nav-link:hover {
  background-color: rgba(27, 31, 10, 0.06);
}
.cid-u8iUylNUta .navbar-nav .open .nav-link::after {
  transform: rotate(180deg);
}
@media (min-width: 992px) {
  .cid-u8iUylNUta .navbar-nav .open .nav-link::before {
    content: "";
    width: 100%;
    height: 20px;
    top: 100%;
    background: transparent;
    position: absolute;
  }
}
.cid-u8iUylNUta .navbar-nav .dropdown-item {
  padding: 12px !important;
  border-radius: 0.5rem !important;
  margin: 0 8px !important;
  transition: 0.3s all !important;
}
.cid-u8iUylNUta .navbar-nav .dropdown-item:hover {
  background-color: rgba(27, 31, 10, 0.06);
}
@media (min-width: 992px) {
  .cid-u8iUylNUta .navbar-nav {
    padding-left: 1.5rem;
  }
}
.cid-u8iUylNUta .nav-link {
  width: fit-content;
  position: relative;
}
.cid-u8iUylNUta .navbar-logo {
  margin: 0 !important;
}
@media (max-width: 767px) {
  .cid-u8iUylNUta .navbar-logo {
    padding-left: 0;
  }
}
.cid-u8iUylNUta .navbar-caption {
  padding-left: 1rem;
  padding-right: 0.5rem;
}
@media (max-width: 767px) {
  .cid-u8iUylNUta .nav-dropdown {
    padding-bottom: 0.5rem;
  }
}
.cid-u8iUylNUta .nav-dropdown .link.dropdown-toggle::after {
  margin-left: 0.5rem;
  margin-top: 0.2rem;
  transition: 0.3s all;
}
.cid-u8iUylNUta .container {
  display: flex;
  height: 90px;
  padding: 0.5rem 0.6rem;
  flex-wrap: nowrap;
  background: rgba(255, 255, 255, 0.8) !important;
  left: 0;
  right: 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100vw;
  margin-top: 1rem;
  background-color: #ffffff;
  box-shadow: 0 30px 60px 0 rgba(27, 31, 10, 0.08);
}
@media (max-width: 992px) {
  .cid-u8iUylNUta .container {
    padding-right: 2rem;
  }
}
@media (max-width: 767px) {
  .cid-u8iUylNUta .container {
    width: 95%;
    height: 56px !important;
    padding-right: 1rem;
    margin-top: 0rem;
  }
}
.cid-u8iUylNUta .iconfont-wrapper {
  color: #000000 !important;
  font-size: 1.5rem;
  padding-right: 0.5rem;
}
.cid-u8iUylNUta .dropdown-menu {
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 100%;
  padding: 12px 4px !important;
  border-radius: 1.5rem;
  transition: 0.3s all !important;
  min-width: auto;
  background: #ffffff;
  background: rgba(255, 255, 255, 0.8) !important;
}
.cid-u8iUylNUta .nav-item:focus,
.cid-u8iUylNUta .nav-link:focus {
  outline: none;
}
.cid-u8iUylNUta .dropdown .dropdown-menu .dropdown-item {
  width: auto;
  transition: all 0.25s ease-in-out;
}
.cid-u8iUylNUta .dropdown .dropdown-menu .dropdown-item::after {
  right: 0.5rem;
}
.cid-u8iUylNUta .dropdown .dropdown-menu .dropdown-item .mbr-iconfont {
  margin-right: 0.5rem;
  vertical-align: sub;
}
.cid-u8iUylNUta .dropdown .dropdown-menu .dropdown-item .mbr-iconfont:before {
  display: inline-block;
  transform: scale(1, 1);
  transition: all 0.25s ease-in-out;
}
.cid-u8iUylNUta .collapsed .dropdown-menu .dropdown-item:before {
  display: none;
}
.cid-u8iUylNUta .collapsed .dropdown .dropdown-menu .dropdown-item {
  padding: 0.235em 1.5em 0.235em 1.5em !important;
  transition: none;
  margin: 0 !important;
}
.cid-u8iUylNUta .navbar {
  min-height: 90px;
  transition: all 0.3s;
  border-bottom: 1px solid transparent;
  background: transparent !important;
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.cid-u8iUylNUta .navbar.opened {
  transition: all 0.3s;
}
.cid-u8iUylNUta .navbar .dropdown-item {
  padding: 0.5rem 1.8rem;
}
.cid-u8iUylNUta .navbar .navbar-logo img {
  min-width: 6rem;
  object-fit: cover;
}
.cid-u8iUylNUta .navbar .navbar-collapse {
  z-index: 1;
  justify-content: flex-end;
}
.cid-u8iUylNUta .navbar.collapsed {
  justify-content: center;
}
.cid-u8iUylNUta .navbar.collapsed .nav-item .nav-link::before {
  display: none;
}
.cid-u8iUylNUta .navbar.collapsed.opened .dropdown-menu {
  top: 0;
}
@media (min-width: 992px) {
  .cid-u8iUylNUta .navbar.collapsed.opened:not(.navbar-short) .navbar-collapse {
    max-height: calc(98.5vh - 4.3rem);
  }
}
.cid-u8iUylNUta .navbar.collapsed .dropdown-menu .dropdown-submenu {
  left: 0 !important;
}
.cid-u8iUylNUta .navbar.collapsed .dropdown-menu .dropdown-item:after {
  right: auto;
}
.cid-u8iUylNUta .navbar.collapsed .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
  margin-left: 0.5rem;
  margin-top: 0.2rem;
  border-top: 0.35em solid;
  border-right: 0.35em solid transparent;
  border-left: 0.35em solid transparent;
  border-bottom: 0;
  top: 41%;
}
.cid-u8iUylNUta .navbar.collapsed ul.navbar-nav li {
  margin: auto;
}
.cid-u8iUylNUta .navbar.collapsed .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
  text-align: center;
}
.cid-u8iUylNUta .navbar.collapsed .icons-menu {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media (max-width: 767px) {
  .cid-u8iUylNUta .navbar {
    min-height: 72px;
  }
  .cid-u8iUylNUta .navbar .navbar-logo img {
    height: 2.5rem !important;
    min-width: 2.5rem !important;
  }
}
@media (max-width: 991px) {
  .cid-u8iUylNUta .navbar .nav-item .nav-link::before {
    display: none;
  }
  .cid-u8iUylNUta .navbar.opened .dropdown-menu {
    top: 0;
  }
  .cid-u8iUylNUta .navbar .dropdown-menu .dropdown-submenu {
    left: 0 !important;
  }
  .cid-u8iUylNUta .navbar .dropdown-menu .dropdown-item:after {
    right: auto;
  }
  .cid-u8iUylNUta .navbar .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
    margin-left: 0.5rem;
    margin-top: 0.2rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0;
    top: 40%;
  }
  .cid-u8iUylNUta .navbar .dropdown-menu .dropdown-item {
    padding: 0.25rem 1.5rem !important;
    text-align: center;
  }
  .cid-u8iUylNUta .navbar .navbar-brand {
    flex-shrink: initial;
    flex-basis: auto;
    word-break: break-word;
    padding-right: 10px;
  }
  .cid-u8iUylNUta .navbar .navbar-toggler {
    flex-basis: auto;
  }
  .cid-u8iUylNUta .navbar .icons-menu {
    padding-left: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
.cid-u8iUylNUta .navbar.navbar-short .navbar-logo img {
  height: 2rem;
}
.cid-u8iUylNUta .dropdown-item.active,
.cid-u8iUylNUta .dropdown-item:active {
  background-color: transparent;
}
.cid-u8iUylNUta .navbar-expand-lg .navbar-nav .nav-link {
  padding: 0;
}
.cid-u8iUylNUta .nav-dropdown .link.dropdown-toggle {
  margin-right: 1.667em;
}
.cid-u8iUylNUta .nav-dropdown .link.dropdown-toggle[aria-expanded="true"] {
  margin-right: 0;
  padding: 0.667em 1.667em;
}
.cid-u8iUylNUta .navbar.navbar-expand-lg .dropdown .dropdown-menu {
  background: #ffffff;
}
.cid-u8iUylNUta .navbar.navbar-expand-lg .dropdown .dropdown-menu .dropdown-submenu {
  margin: 0;
  left: 105%;
  transform: none;
  top: -12px;
}
.cid-u8iUylNUta .navbar .dropdown.open > .dropdown-menu {
  display: flex;
}
.cid-u8iUylNUta ul.navbar-nav {
  flex-wrap: wrap;
}
.cid-u8iUylNUta .navbar-buttons {
  text-align: center;
  min-width: 140px;
}
@media (max-width: 992px) {
  .cid-u8iUylNUta .navbar-buttons {
    text-align: left;
  }
}
.cid-u8iUylNUta button.navbar-toggler {
  outline: none;
  width: 31px;
  height: 20px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  align-self: center;
}
.cid-u8iUylNUta button.navbar-toggler .hamburger span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: #000000;
}
.cid-u8iUylNUta button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all 0.2s;
}
.cid-u8iUylNUta button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all 0.15s;
}
.cid-u8iUylNUta button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all 0.15s;
}
.cid-u8iUylNUta button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all 0.2s;
}
.cid-u8iUylNUta nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-u8iUylNUta nav.opened .hamburger span:nth-child(2) {
  transform: rotate(45deg);
  transition: all 0.25s;
}
.cid-u8iUylNUta nav.opened .hamburger span:nth-child(3) {
  transform: rotate(-45deg);
  transition: all 0.25s;
}
.cid-u8iUylNUta nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}
.cid-u8iUylNUta .navbar-dropdown {
  padding: 0 1rem;
}
.cid-u8iUylNUta a.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cid-u8iUylNUta .icons-menu {
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.3rem;
  text-align: center;
}
@media (max-width: 992px) {
  .cid-u8iUylNUta .icons-menu {
    justify-content: flex-start;
    margin-bottom: 0.5rem;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cid-u8iUylNUta .navbar {
    height: 70px;
  }
  .cid-u8iUylNUta .navbar.opened {
    height: auto;
  }
  .cid-u8iUylNUta .nav-item .nav-link:hover::before {
    width: 175%;
    max-width: calc(100% + 2rem);
    left: -1rem;
  }
}
.cid-u8iUylNUta .navbar .dropdown > .dropdown-menu {
  display: none;
  width: max-content;
  max-width: 500px !important;
  transform: translateX(-50%);
  top: calc(100% + 20px);
  left: 50%;
}
.cid-u8iUylNUta .navbar .dropdown > .dropdown-menu .dropdown-item {
  line-height: 1 !important;
}
.cid-u8iUylNUta .navbar .dropdown > .dropdown-menu .dropdown .dropdown-item {
  align-items: center;
  display: flex;
  height: max-content !important;
  min-height: max-content !important;
}
.cid-u8iUylNUta .navbar .dropdown > .dropdown-menu .dropdown .dropdown-item::after {
  display: inline-block;
  position: static;
  margin-left: 0.5rem;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  transition: 0.3s all;
  transform: rotate(-90deg);
}
.cid-u8iUylNUta .navbar .dropdown > .dropdown-menu .dropdown.open .dropdown-item::after {
  transform: rotate(0deg);
}
.cid-u8iUylNUta .mbr-section-btn {
  margin: -0.6rem -0.6rem;
}
.cid-u8iUylNUta .navbar-toggler {
  margin-left: 12px;
  margin-right: 8px;
  order: 1000;
}
@media (max-width: 991px) {
  .cid-u8iUylNUta .navbar-brand {
    margin-right: auto;
  }
  .cid-u8iUylNUta .navbar-collapse {
    z-index: -1 !important;
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    padding: 1rem;
    border-radius: 1.5rem;
    background: #ffffff;
    opacity: 1;
    border-color: rgba(255, 255, 255, 0.8) !important;
    background: rgba(255, 255, 255, 0.8) !important;
    backdrop-filter: blur(8px);
  }
  .cid-u8iUylNUta .navbar-nav .nav-item .nav-link::after {
    margin-left: 10px;
  }
  .cid-u8iUylNUta .navbar-nav .dropdown-item:hover {
    background-color: rgba(27, 31, 10, 0.06);
  }
  .cid-u8iUylNUta .navbar .dropdown > .dropdown-menu {
    max-width: 100% !important;
    transform: translateX(0);
    top: 10px;
    left: 0;
    padding: 8px !important;
    border-radius: 1rem;
    background-color: rgba(27, 31, 10, 0.04) !important;
  }
  .cid-u8iUylNUta .navbar .dropdown > .dropdown-menu .dropdown-item {
    padding: 8px !important;
    line-height: 1 !important;
    margin-bottom: 4px !important;
  }
  .cid-u8iUylNUta .navbar .dropdown > .dropdown-menu .dropdown .dropdown-item {
    align-items: center;
    display: flex;
    height: max-content !important;
    min-height: max-content !important;
  }
  .cid-u8iUylNUta .navbar .dropdown > .dropdown-menu .dropdown .dropdown-item::after {
    display: inline-block;
    position: static;
    margin-left: 0.5rem;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    transition: 0.3s all;
    transform: rotate(0deg);
  }
  .cid-u8iUylNUta .navbar .dropdown > .dropdown-menu .dropdown.open .dropdown-item::after {
    transform: rotate(180deg);
  }
  .cid-u8iUylNUta .navbar .dropdown > .dropdown-menu .dropdown-submenu {
    position: static;
    width: 100%;
    max-width: 100% !important;
    transform: translateX(0) !important;
    top: 0;
    left: 0;
    padding: 8px !important;
    border-radius: 1rem;
    background-color: rgba(27, 31, 10, 0.04) !important;
  }
  .cid-u8iUylNUta .navbar .dropdown.open > .dropdown-menu {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 575px) {
  .cid-u8iUylNUta .navbar-collapse {
    padding: 1rem;
  }
}
.cid-u8iVJNQJoo {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #edefeb;
}
.cid-u8iVJNQJoo .mbr-fallback-image.disabled {
  display: none;
}
.cid-u8iVJNQJoo .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
@media (max-width: 991px) {
  .cid-u8iVJNQJoo .image-wrapper {
    margin-bottom: 1rem;
  }
}
.cid-u8iVJNQJoo .row {
  flex-direction: row-reverse;
}
.cid-u8iVJNQJoo .row {
  align-items: center;
}
@media (max-width: 991px) {
  .cid-u8iVJNQJoo .image-wrapper {
    padding: 1rem;
  }
}
@media (min-width: 992px) {
  .cid-u8iVJNQJoo .text-wrapper {
    padding: 0 2rem;
  }
}
.cid-u8iVJNQJoo .mbr-section-title {
  color: #000000;
}
.cid-u8iVJNQJoo .mbr-text,
.cid-u8iVJNQJoo .mbr-section-btn {
  color: #232323;
}
.cid-u8iWTwlabj {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #edefeb;
}
.cid-u8iWTwlabj .mbr-overlay {
  background-color: #ffffff;
  opacity: 0.4;
}
.cid-u8iWTwlabj form .mbr-section-btn {
  text-align: center;
  width: 100%;
}
.cid-u8iWTwlabj form .mbr-section-btn .btn {
  display: inline-flex;
}
@media (max-width: 991px) {
  .cid-u8iWTwlabj form .mbr-section-btn .btn {
    width: 100%;
  }
}
.cid-u8iWTwlabj .content-head {
  max-width: 800px;
}
.cid-u8iXHdmWns {
  display: flex;
  background-image: url("../../../assets/images/background17.jpg");
}
.cid-u8iXHdmWns .mbr-overlay {
  background-color: #000000;
  opacity: 0.5;
}
@media (min-width: 768px) {
  .cid-u8iXHdmWns {
    align-items: flex-end;
  }
  .cid-u8iXHdmWns .row {
    justify-content: center;
  }
  .cid-u8iXHdmWns .content-wrap {
    padding: 1rem 3rem;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .cid-u8iXHdmWns .content-wrap {
    min-width: 50%;
  }
}
@media (max-width: 767px) {
  .cid-u8iXHdmWns {
    -webkit-align-items: center;
    align-items: flex-end;
  }
  .cid-u8iXHdmWns .mbr-row {
    -webkit-justify-content: center;
    justify-content: center;
  }
  .cid-u8iXHdmWns .content-wrap {
    width: 100%;
  }
}
.cid-u8iXHdmWns .mbr-section-title,
.cid-u8iXHdmWns .mbr-section-subtitle {
  text-align: center;
}
.cid-u8iXHdmWns .mbr-text,
.cid-u8iXHdmWns .mbr-section-btn {
  text-align: center;
}
.cid-u8iUylPc33 {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: transparent;
}
.cid-u8iUylPc33 .mbr-fallback-image.disabled {
  display: none;
}
.cid-u8iUylPc33 .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-u8iUylPc33 .card-wrapper {
  background: #ffffff;
}
@media (max-width: 767px) {
  .cid-u8iUylPc33 .card-wrapper {
    padding: 2rem 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cid-u8iUylPc33 .card-wrapper {
    padding: 2.25rem;
  }
}
@media (min-width: 992px) {
  .cid-u8iUylPc33 .card-wrapper {
    padding: 4rem;
  }
}
.cid-u8iUylPc33 .mbr-text,
.cid-u8iUylPc33 .mbr-section-btn {
  color: #000000;
  text-align: left;
}
.cid-u8iUylPc33 .card-title,
.cid-u8iUylPc33 .card-box {
  text-align: left;
  color: #000000;
}
.cid-u8iUylNjUX {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #ffffff;
}
.cid-u8iUylNjUX .mbr-text {
  color: #000000;
}
.cid-u8iUylNjUX .mbr-section-subtitle {
  color: #000000;
}
.cid-u8iUylNGLk {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #ffffff;
}
.cid-u8iUylNGLk .item:focus,
.cid-u8iUylNGLk span:focus {
  outline: none;
}
.cid-u8iUylNGLk .item {
  cursor: pointer;
}
.cid-u8iUylNGLk .grid-container {
  grid-row-gap: 2rem;
}
@media (max-width: 767px) {
  .cid-u8iUylNGLk .grid-container {
    grid-row-gap: 1rem;
  }
}
.cid-u8iUylNGLk .grid-container-1,
.cid-u8iUylNGLk .grid-container-2,
.cid-u8iUylNGLk .grid-container-3 {
  gap: 0 2rem;
}
@media (max-width: 767px) {
  .cid-u8iUylNGLk .grid-container-1,
  .cid-u8iUylNGLk .grid-container-2,
  .cid-u8iUylNGLk .grid-container-3 {
    gap: 0 1rem;
  }
}
.cid-u8iUylNGLk .mbr-section-title {
  color: #000000;
}
.cid-u8iUylNGLk .mbr-text,
.cid-u8iUylNGLk .mbr-section-btn {
  color: #000000;
}
.cid-u8iUylNGLk .content-head {
  max-width: 800px;
}
.cid-u8iUylNGLk .container,
.cid-u8iUylNGLk .container-fluid {
  overflow: hidden;
}
.cid-u8iUylNGLk .grid-container {
  display: grid;
  transform: translate3d(-3rem, 0, 0);
  width: 115vw;
  grid-column-gap: 1rem;
}
.cid-u8iUylNGLk .grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cid-u8iUylNGLk .grid-item img {
  min-width: 30vw;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .cid-u8iUylNGLk .grid-item img {
    min-width: 35vw;
  }
}
.cid-u8iUylNGLk .grid-container-1,
.cid-u8iUylNGLk .grid-container-2,
.cid-u8iUylNGLk .grid-container-3 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
}
.cid-u8iUylNGLk .grid-container-1 {
  align-items: flex-end;
}
.cid-u8iUylNGLk .grid-container-2 {
  align-items: flex-start;
}
.cid-u8iUylNE77 {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: transparent;
}
.cid-u8iUylNE77 .mbr-fallback-image.disabled {
  display: none;
}
.cid-u8iUylNE77 .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-u8iUylNE77 .mbr-description {
  text-align: left;
}
.cid-u8iUylNE77 .item-wrapper {
  background: #ffffff;
  padding: 2.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  .cid-u8iUylNE77 .item-wrapper {
    padding: 2rem 1.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .cid-u8iUylNE77 .item-wrapper {
    padding: 2rem 1.5rem;
  }
}
.cid-u8iUylNE77 .card-title,
.cid-u8iUylNE77 .iconfont-wrapper {
  color: #000000;
}
.cid-u8iUylNE77 .card-text {
  color: #000000;
}
.cid-u8iUylNE77 .mbr-section-title {
  color: #000000;
  text-align: center;
}
.cid-u8iUylNE77 .mbr-number {
  color: #9fe870;
}
.cid-u8iUylNE77 .mbr-text,
.cid-u8iUylNE77 .mbr-section-btn {
  text-align: center;
}
.cid-u8iUylNE77 .card-text,
.cid-u8iUylNE77 .item .mbr-section-btn {
  text-align: left;
}
.cid-u8iUylNE77 .mbr-section-btn {
  margin-top: 1rem;
}
.cid-u8iUylNE77 .image-wrapper {
  margin-bottom: 0rem;
  padding: 0rem;
}
@media (max-width: 767px) {
  .cid-u8iUylNE77 .image-wrapper {
    padding: 0rem 0rem;
    margin-bottom: 0rem;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .cid-u8iUylNE77 .image-wrapper {
    padding: 0rem 0rem;
    margin-bottom: 0rem;
  }
}
.cid-u8iUylNE77 .image-wrapper img {
  height: 100%;
  object-fit: cover;
}
.cid-u8iUylNE77 .item-mb {
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .cid-u8iUylNE77 .item-mb {
    margin-bottom: 1rem;
  }
}
.cid-u8iUylNE77 .card-title {
  text-align: left;
}
.cid-u8iUylNE77 .item-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.cid-u8iUylNE77 .item-content .item-footer {
  margin-top: auto;
}
.cid-u8iUylOgg1 {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #ffffff;
}
.cid-u8iUylOgg1 .item-subtitle {
  line-height: 1.2;
  color: #232323;
  text-align: center;
}
.cid-u8iUylOgg1 img,
.cid-u8iUylOgg1 .item-img {
  width: 100%;
  height: 100%;
  height: 400px;
  object-fit: cover;
}
.cid-u8iUylOgg1 .item:focus,
.cid-u8iUylOgg1 span:focus {
  outline: none;
}
.cid-u8iUylOgg1 .item {
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .cid-u8iUylOgg1 .item {
    margin-bottom: 1rem;
  }
}
.cid-u8iUylOgg1 .item-wrapper {
  position: relative;
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}
.cid-u8iUylOgg1 .mbr-section-title {
  color: #232323;
}
.cid-u8iUylOgg1 .mbr-text,
.cid-u8iUylOgg1 .mbr-section-btn {
  color: #232323;
}
.cid-u8iUylOgg1 .item-title {
  color: #232323;
}
.cid-u8iUylOgg1 .content-head {
  max-width: 800px;
}
.cid-u8iUylOL7i {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #ffffff;
}
.cid-u8iUylOL7i .mbr-fallback-image.disabled {
  display: none;
}
.cid-u8iUylOL7i .item-wrapper {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.cid-u8iUylOL7i .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-u8iUylOL7i .mbr-iconfont {
  display: inline-flex;
  font-size: 2rem;
  color: #d70081;
  width: 80px;
  justify-content: center;
  align-items: center;
  background: #ffd7ef;
  height: 80px;
  border-radius: 50%;
}
.cid-u8iUylOL7i .card-title,
.cid-u8iUylOL7i .iconfont-wrapper,
.cid-u8iUylOL7i .card-text {
  color: #000000;
  text-align: center;
}
.cid-u8iUylOL7i .content-head {
  max-width: 800px;
}
.cid-u8iUylOL7i .mbr-section-title {
  color: #000000;
}
.cid-u8iUylOL7i .mbr-section-btn {
  text-align: center;
}
.cid-u8iUylOL7i .card-title,
.cid-u8iUylOL7i .iconfont-wrapper {
  color: #8AC926;
}
.cid-u8iUylPlKh {
  padding-top: 5rem;
  padding-bottom: 4rem;
  background-color: transparent;
}
.cid-u8iUylPlKh .item-mb {
  margin-bottom: 2rem;
}
@media (max-width: 767px) {
  .cid-u8iUylPlKh .item-mb {
    margin-bottom: 1rem;
  }
}
.cid-u8iUylPlKh .item-head {
  background: #8ac926;
  padding: 2.25rem;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .cid-u8iUylPlKh .item-head {
    padding: 2rem 1.5rem;
    margin-bottom: 2rem;
  }
}
@media (max-width: 767px) {
  .cid-u8iUylPlKh .item-head {
    padding: 2rem 1.5rem;
    margin-bottom: 2rem;
  }
}
.cid-u8iUylPlKh .item-content {
  padding: 2.25rem 2.25rem 0;
  background: #ffffff;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .cid-u8iUylPlKh .item-content {
    padding: 0rem 1.5rem;
  }
}
@media (max-width: 767px) {
  .cid-u8iUylPlKh .item-content {
    padding: 0rem 1.5rem;
  }
}
.cid-u8iUylPlKh .item-wrapper {
  border-radius: 2rem;
  overflow: hidden;
  margin-bottom: 2rem;
  background: #ffffff;
  padding: 0rem;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}
@media (max-width: 767px) {
  .cid-u8iUylPlKh .item-wrapper {
    margin-bottom: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .cid-u8iUylPlKh .item-wrapper .item-footer {
    padding: 0 1.5rem 2rem;
  }
}
@media (min-width: 1201px) {
  .cid-u8iUylPlKh .item-wrapper .item-footer {
    padding: 0 2rem 2rem;
  }
}
.cid-u8iUylPlKh .btn {
  width: -webkit-fill-available;
}
.cid-u8iUylPlKh .item:focus,
.cid-u8iUylPlKh span:focus {
  outline: none;
}
.cid-u8iUylPlKh .mbr-section-btn {
  margin-top: auto !important;
  padding: 2rem 2rem 0;
}
@media (max-width: 991px) {
  .cid-u8iUylPlKh .mbr-section-btn {
    padding: 0rem 2.25rem 2rem;
  }
}
@media (max-width: 767px) {
  .cid-u8iUylPlKh .mbr-section-btn {
    padding: 0rem 1.5rem;
    margin-bottom: 2rem;
  }
}
.cid-u8iUylPlKh .mbr-section-title {
  color: #000000;
}
.cid-u8iUylPlKh .mbr-section-subtitle {
  color: #ffffff;
}
.cid-u8iUylPlKh .mbr-text,
.cid-u8iUylPlKh .mbr-section-btn {
  text-align: left;
}
.cid-u8iUylPlKh .item-title {
  text-align: left;
  color: var(--primary-text, #ffffff);
}
.cid-u8iUylPlKh .item-subtitle {
  text-align: left;
  color: var(--primary-text, #ffffff);
}
.cid-u8iUylPlKh .content-head {
  max-width: 800px;
}
.cid-u8iUylP96E {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: transparent;
}
.cid-u8iUylP96E .mbr-fallback-image.disabled {
  display: none;
}
.cid-u8iUylP96E .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-u8iUylP96E .card-wrapper {
  background: #ff595e;
}
@media (max-width: 767px) {
  .cid-u8iUylP96E .card-wrapper {
    padding: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .cid-u8iUylP96E .card-wrapper {
    padding: 2.25rem;
  }
}
@media (min-width: 992px) {
  .cid-u8iUylP96E .card-wrapper {
    padding: 4rem;
  }
}
.cid-u8iUylP96E .mbr-text,
.cid-u8iUylP96E .mbr-section-btn {
  color: #000000;
}
.cid-u8iUylP96E .card-title,
.cid-u8iUylP96E .card-box {
  text-align: center;
  color: #000000;
}
.cid-u8iUylQNbZ {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: transparent;
}
.cid-u8iUylQNbZ .mbr-iconfont {
  font-size: 1.2rem !important;
  font-family: 'Moririse2' !important;
  color: white;
  transition: all 0.3s;
  transform: rotate(180deg);
  padding-left: 0.5rem;
}
.cid-u8iUylQNbZ .panel-group {
  border: none;
}
.cid-u8iUylQNbZ .card-header {
  padding: 0rem;
}
@media (max-width: 767px) {
  .cid-u8iUylQNbZ .card-header {
    padding: 0rem;
  }
}
.cid-u8iUylQNbZ .panel-body {
  padding: 0rem;
  padding-bottom: 0.5rem;
  margin-top: 1rem;
}
.cid-u8iUylQNbZ .img-col {
  padding: 0;
}
.cid-u8iUylQNbZ .img-item {
  height: 100%;
}
.cid-u8iUylQNbZ img {
  height: 100%;
  object-fit: cover;
}
.cid-u8iUylQNbZ .collapsed span {
  transform: rotate(0deg);
}
.cid-u8iUylQNbZ .panel-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cid-u8iUylQNbZ p {
  margin-bottom: 0.3rem;
}
.cid-u8iUylQNbZ .panel-title-edit {
  color: #000000;
}
.cid-u8iUylQNbZ .card .card-header {
  background-color: transparent;
  margin-bottom: 0;
  border: 0;
  border-radius: 2rem;
}
.cid-u8iUylQNbZ .card {
  background: #ffffff;
  padding: 2.25rem;
  border-radius: 2rem;
  margin-bottom: 1rem;
}
@media (max-width: 767px) {
  .cid-u8iUylQNbZ .card {
    padding: 2rem 1.5rem;
    margin-bottom: 1rem;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .cid-u8iUylQNbZ .card {
    margin-bottom: 1rem;
    padding: 2rem 1.5rem;
  }
}
.cid-u8iUylQNbZ .panel-text {
  color: #000000;
}
.cid-u8iUylQNbZ .mbr-section-title {
  text-align: center;
  color: #000000;
}
.cid-u8iUylQNbZ .mbr-section-subtitle {
  color: #000000;
  text-align: center;
}
.cid-u8iUylQNbZ .panel-title-edit,
.cid-u8iUylQNbZ .mbr-iconfont {
  color: #000000;
}
.cid-u8iUylQM2c {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #ffffff;
}
.cid-u8iUylQM2c .mbr-fallback-image.disabled {
  display: none;
}
.cid-u8iUylQM2c .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-u8iUylQM2c .row {
  flex-direction: row-reverse;
}
.cid-u8iUylQuCF {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: transparent;
}
.cid-u8iUylQuCF .item-wrapper img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50% !important;
}
.cid-u8iUylQuCF .item-wrapper {
  background: #ffffff;
  margin-bottom: 2rem;
  padding: 2.25rem;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .cid-u8iUylQuCF .item-wrapper {
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
}
@media (max-width: 767px) {
  .cid-u8iUylQuCF .item-wrapper {
    padding: 1.5rem;
    margin-bottom: 1rem;
  }
}
.cid-u8iUylQuCF .card-title,
.cid-u8iUylQuCF .iconfont-wrapper {
  color: #000000;
}
.cid-u8iUylQuCF .card-text {
  color: #000000;
  text-align: left;
}
.cid-u8iUylQuCF .content-head {
  max-width: 800px;
}
.cid-u8iUylQuCF .mbr-section-title {
  color: #000000;
}
.cid-u8iUylQuCF .card-title,
.cid-u8iUylQuCF .img-wrapper {
  text-align: center;
}
.cid-u8iUylQuCF .img-wrapper {
  display: flex;
  justify-content: center;
}
.cid-u8iUylRNtx {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #ffffff;
}
.cid-u8iUylRNtx .social-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.cid-u8iUylRNtx .social-row .soc-item {
  margin: 8px;
}
.cid-u8iUylRNtx .social-row .soc-item a:hover .mbr-iconfont,
.cid-u8iUylRNtx .social-row .soc-item a:focus .mbr-iconfont {
  background-color: #f7f7f7;
}
.cid-u8iUylRNtx .social-row .soc-item a .mbr-iconfont {
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 32px;
  background-color: #f7f7f7;
  color: #393193;
  transition: all 0.3s ease-in-out;
}
.cid-u8iUylRNtx .row-links {
  width: 100%;
  justify-content: center;
}
.cid-u8iUylRNtx .mbr-section-title {
  color: #ffffff;
}
.cid-u8iUylRNtx .mbr-section-title,
.cid-u8iUylRNtx .social-list {
  color: #000000;
}
.cid-u8iUylRHcU {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #ffffff;
}
.cid-u8iUylRHcU .item-subtitle {
  line-height: 1.2;
  color: #000000;
}
.cid-u8iUylRHcU img,
.cid-u8iUylRHcU .item-img {
  width: 100%;
}
.cid-u8iUylRHcU .item:focus,
.cid-u8iUylRHcU span:focus {
  outline: none;
}
.cid-u8iUylRHcU .item {
  margin-bottom: 2rem;
}
@media (max-width: 575px) {
  .cid-u8iUylRHcU .item {
    margin-bottom: 1rem;
  }
}
.cid-u8iUylRHcU .item-wrapper {
  position: relative;
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}
.cid-u8iUylRHcU .mbr-section-title {
  color: #000000;
}
.cid-u8iUylRHcU .mbr-text,
.cid-u8iUylRHcU .mbr-section-btn {
  color: #000000;
}
.cid-u8iUylRHcU .item-title {
  color: #000000;
  text-align: center;
}
.cid-u8iUylRHcU .content-head {
  max-width: 800px;
}
.cid-u8iUylSZ0Z {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: transparent;
}
.cid-u8iUylSZ0Z .mbr-overlay {
  background-color: #ffffff;
  opacity: 0.4;
}
.cid-u8iUylSZ0Z form .mbr-section-btn {
  text-align: center;
  width: 100%;
}
.cid-u8iUylSZ0Z form .mbr-section-btn .btn {
  display: inline-flex;
}
@media (max-width: 991px) {
  .cid-u8iUylSZ0Z form .mbr-section-btn .btn {
    width: 100%;
  }
}
.cid-u8iUylSZ0Z .content-head {
  max-width: 800px;
}
.cid-u8iUylSxXm {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: transparent;
}
.cid-u8iUylSxXm .mbr-fallback-image.disabled {
  display: none;
}
.cid-u8iUylSxXm .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
@media (max-width: 992px) {
  .cid-u8iUylSxXm .row .map-wrapper {
    margin-top: 2rem;
  }
}
@media (max-width: 767px) {
  .cid-u8iUylSxXm .row .map-wrapper {
    margin-top: 1rem;
  }
}
.cid-u8iUylSxXm .google-map {
  height: 100%;
  position: relative;
}
.cid-u8iUylSxXm .google-map iframe {
  height: 100%;
  width: 100%;
}
@media (max-width: 992px) {
  .cid-u8iUylSxXm .google-map iframe {
    min-height: 350px;
  }
}
.cid-u8iUylSxXm .google-map [data-state-details] {
  color: #6b6763;
  height: 1.5em;
  margin-top: -0.75em;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
}
.cid-u8iUylSxXm .google-map[data-state] {
  background: #e9e5dc;
}
.cid-u8iUylSxXm .google-map[data-state="loading"] [data-state-details] {
  display: none;
}
.cid-u8iUylSxXm .card-wrapper {
  padding: 2.25rem;
  background: #ffffff;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .cid-u8iUylSxXm .card-wrapper {
    padding: 1.5rem;
  }
}
@media (max-width: 767px) {
  .cid-u8iUylSxXm .card-wrapper {
    padding: 2rem 1.5rem;
  }
}
.cid-u8iUylSxXm ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.cid-u8iUylSxXm .content-head {
  max-width: 800px;
}
.cid-u8iUylTsMO {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #000000;
}
.cid-u8iUylTsMO .mbr-fallback-image.disabled {
  display: none;
}
.cid-u8iUylTsMO .mbr-fallback-image {
  display: block;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.cid-u8iUylTsMO .copyright {
  color: #ffffff;
  text-align: left;
}
.cid-u8iUylTsMO .center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 991px) {
  .cid-u8iUylTsMO .row {
    flex-direction: column-reverse !important;
  }
  .cid-u8iUylTsMO .row .copyright {
    margin: 1rem 0 0 0;
  }
}
.cid-u8iUylTsMO .row-links {
  width: 100%;
  justify-content: center;
}
.cid-u8iUylTsMO .row-links .row-links-soc {
  list-style: none;
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .cid-u8iUylTsMO .row-links .row-links-soc {
    justify-content: center;
  }
}
.cid-u8iUylTsMO .row-links .row-links-soc li {
  padding: 0 1rem 0rem 1rem;
}
@media (max-width: 767px) {
  .cid-u8iUylTsMO .row-links .row-links-soc li {
    padding: 0 1rem 1rem 1rem;
  }
}
.cid-u8iUylTsMO .row-links .row-links-soc li p {
  margin: 0;
}
@media (max-width: 991px) {
  .cid-u8iUylTsMO .copyright {
    text-align: center;
  }
}
