@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0");
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  background-color: #ffffff;
  font-family: "Public Sans";
  color: #212b36;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}
.btn-primary,
.btn-primary:active {
  background-color: #055a93 !important;
  border-color: #055a93 !important;
}

.btn {
  padding: 1rem 2rem;
}

.cid-u8iUylNUta .btn-primary,
.btn-primary:active {
  background-color: #cbb16e !important;
  border-color: #cbb16e !important;
}

.cid-u8iUylNUta .container {
  max-width: 100%;
  border-radius: 0;
}

.cid-u8iUylNUta .navbar-logo img {
  height: 4.3rem;
}

.cid-u8iVJNQJoo {
  margin-top: 60px;
}

.cid-u8iUylNE77 {
  background-color: #b4cddf;
}

.cid-u8iUylNE77 .imgtxtbody {
  position: absolute;
  bottom: 10px;
  left: 7%;
}
.cid-u8iUylNE77 .imgtxtbody h2,
.cid-u8iUylNE77 .imgtxtbody div {
  color: #ffffff;
}

.cid-u8iUylQM2c {
  background-color: #f1dd90;
}

.cid-u8iUylQNbZ {
  background-color: #e6edf3;
}

.cid-u8iUylNjUX {
  margin-top: 5%;
}

.cid-u8iUylQM2c .item-wrapper {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 2rem;
  overflow: hidden;
}

.cid-u8iUylQM2c .item-image,
.cid-u8iUylQM2c img {
  width: 100%;
  height: 100%;
  height: 400px;
  object-fit: cover;
}

.cid-u8iUylQM2c .item-titlearea {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding-left: 20px;
  padding-top: 5px;
}
.myImage {
  border-radius: 20px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  object-fit: cover;
}

.bottomImage {
  border-radius: 20px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  object-fit: cover;
}

.myImage:hover {
  opacity: 1;
  cursor: pointer;
  filter: grayscale(100%);
}
.bottomImage:hover {
  opacity: 1;
  cursor: pointer;
  filter: grayscale(100%);
}
/* .bottomleft {
  position: absolute;
  bottom: 8px;
  left: 16px;
  font-size: 18px;
  z-index: 1000;
}
.bottomleft2 {
  position: absolute;
  bottom: 8px;
  left: 16px;
  font-size: 18px;
  z-index: 1000;
} */
.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008cba;
}

#carasel {
  border-radius: 0 !important;
}

.cid-u8iUylQM2c .item-titlearea h2,
.cid-u8iUylQM2c .item-titlearea p {
  color: #ffffff;
}

.cid-u8iUylNE77 .item.features-without-image,
.cid-u8iUylQM2c .item.features-image {
  cursor: pointer;
}

.cid-u8iUylQuCF .item-wrapper img {
  width: 200px;
  height: 200px;
}

.cid-u8iUylQuCF {
  background-color: #eeeeee;
}

.cid-u8iUylRNtx .container {
  max-width: 95%;
}

.cid-u8iUylRNtx .social-row .soc-item a .mbr-iconfont {
  width: 60px;
  height: 60px;
}

@keyframes enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.modalContainer {
  background-color: rgba(22, 22, 22, 0.7);
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 1000;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation-name: enter;
  animation-duration: 0.5s;
}
.close {
  color: white;
  position: absolute;
  top: 15%;
  right: 25%;
  font-size: 30px;
  align-self: flex-end;
  cursor: pointer;
}

modal.open {
  display: block !important;
}
#iconLeft {
  color: white;
  font-size: 60px;
  cursor: pointer;
  margin-right: 50px;
  user-select: none;
}

.innerContainer {
  position: relative;
  background-color: rgba(22, 22, 22, 1);
  height: 60%;
  min-height: 200px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.imageSlider {
  width: 90% !important;
  height: 90% !important;
  object-fit: cover;
  max-height: 610px;
}
.chatbot-toggler {
  position: fixed;
  right: 40px;
  bottom: 35px;
  height: 50px;
  width: 50px;
  color: #fff;
  background-color: #cbb16e !important;
  border-color: #cbb16e !important;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.chatRoom {
  /* border-width: 3px;
  border-color: red;
  border-style: solid; */
  width: 25%;
  min-height: 500px;
  z-index: 1000;
  position: fixed;
  right: 3%;
  bottom: 10%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  background: white;
  border-radius: 15px;
  transform-origin: bottom right;
  box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.1),
    0 32px 64px -48px rgba(0, 0, 0, 0.5);
  transition: all 0.1s ease;
  /* position: absolute;
    right: 40px;
    bottom: 100px;
    width: 420px;
    transform: scale(0.5);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    background: white;
    border-radius: 15px;
    border-color: red;
    border-width: 3px;
    border-style: solid;
    transform-origin: bottom right;
    box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.1), 0 32px 64px -48px rgba(0, 0, 0, 0.5);
    transition: all 0.1s ease;
    z-index: 1000; */
}

#iconRight {
  color: white;
  font-size: 60px;
  cursor: pointer;
  margin-left: 50px;
  user-select: none;
}
.input-box {
  border-color: gray;
  border-width: 1px;
  border-style: solid;
  margin-top: 20px;
}

.input-wrapper {
  position: relative;
}

.name {
  border: 1px solid rgba(145, 158, 171, 0.5);
  border-radius: 6px;
  position: relative;
  width: 200px;
  margin: 15px;
  line-height: 5ex;
  margin-left: 15px;
  padding: 1px;
  color: black;
}

.email {
  border: 1px solid rgba(145, 158, 171, 0.5);
  border-radius: 6px;
  position: relative;
  width: 200px;
  margin: 15px;
  line-height: 5ex;
  padding: 1px;
  color: black;
}

.bannerImg {
  width: 100vw;
  height: 60vh;
  object-fit: cover;
  opacity: 0.3;
  border-radius: 0px;
  filter: contrast(180%);
  object-position: 80% 40%;
}

.welcome-container {
  position: absolute;
  z-index: 10;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 100px;
  margin-top: auto;
  margin-bottom: auto;
  width: 50%;
  height: 20%;
  }


.phone-number {
  border: 1px solid rgba(145, 158, 171, 0.5);
  border-radius: 6px;
  position: relative;
  width: 430px;
  margin: 12.5px;
  line-height: 5ex;
  color: black;
}

.input-message {
  border: 1px solid rgba(145, 158, 171, 0.5);
  border-radius: 6px;
  position: relative;
  width: 430px;
  margin: 10px;
  line-height: 5ex;
  color: black;
}

label {
  position: absolute;
  top: 0.2ex;
  z-index: 1;
  left: 2em;
  background-color: white;
  padding: 0 5px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Public Sans", sans-serif;
}
.home__container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home__container > * {
  margin-bottom: 10px;
}
.home__header {
  margin-bottom: 30px;
}
.username__input {
  padding: 10px;
  width: 50%;
}
.home__cta {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #607eaa;
  color: #f9f5eb;
  outline: none;
  border: none;
  border-radius: 5px;
}
#prettyPicture {
  border-radius: 0 !important;
}
.chat {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}
.chat__sidebar {
  height: 100%;
  background-color: #f9f5eb;
  flex: 0.2;
  padding: 20px;
  border-right: 1px solid #fdfdfd;
}
.chat__main {
  height: 100%;
  flex: 0.8;
}
.chat__header {
  margin: 30px 0 20px 0;
}
.chat__users > * {
  margin-bottom: 10px;
  color: #607eaa;
  font-size: 14px;
}
.online__users > * {
  margin-bottom: 10px;
  color: rgb(238, 102, 102);
  font-style: italic;
}
.chat__mainHeader {
  width: 100%;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 20px; */
  background-color: #cbb16e !important;
  border-color: #cbb16e !important;
}
.leaveChat__btn {
  padding: 10px;
  width: 150px;
  border: none;
  outline: none;
  background-color: #d1512d;
  cursor: pointer;
  color: #eae3d2;
}
.message__container {
  /* position: fixed; */
  /* border-color: blue;
  border-style: solid;
  border-width: 3px; */
  height: 400px;
  padding-right: 20px;
  padding-bottom: 20px;
  overflow-y: scroll;
  background-color: #fff;
  /* width: 100%;
  height: 80vh;
  background-color: #fff;
  padding: 20px;
  overflow-y: scroll; */
}

.message__container > * {
  margin-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
}
.chat__footer {
  /* padding: 10px; */
  position: absolute;
  border-top: 1px solid #ccc;
  bottom: 0;
  width: 100%;
  background-color: white;
  /* height: 5vh; */
}
.form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.message {
  width: 80%;
  height: 100%;
  border-radius: 10px;
  border: none;
  /* border: 1px solid #ddd; */
  outline: none;
  padding: 15px;
}
.sendBtn {
  width: 150px;
  background-color: green;
  padding: 10px;
  margin-right: 20px;
  border: none;
  outline: none;
  color: #eae3d2;
  cursor: pointer;
}

#send-btn {
  margin-right: 20px;
  cursor: pointer;
}

#send-btn:hover {
  background-color: rgb(129, 201, 129);
}

.suggestions {
  position: absolute;
  left: 0;
  top: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  background-color: #ffffff;
  z-index: 1000;
}

.suggestions ul {
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: #faed81;
  border: solid #dddddd 1px;
}

.suggestions ul li {
  cursor: pointer;
  padding: 10px;
  border-bottom: solid #dddddd 1px;
  color: #000000;
}

.suggestions ul li:hover {
  background-color: #eeeeee;
}


.message__recipient {
  background-color: #b4cddf;
  width: 200px;
  max-width: 300px;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
}
.message__sender {
  background-color: #f1dd90;
  width: 200px;
  max-width: 300px;
  padding: 10px;
  border-radius: 10px 10px 0 10px;
  margin-left: auto;
  font-size: 15px;
  margin-right: 5px;
  text-align: left;
}
.message__chats > p {
  font-size: 13px;
}
.sender__name {
  text-align: right;
  margin-right: 7px;
}
.message__status {
  position: fixed;
  bottom: 50px;
  font-size: 13px;
  font-style: italic;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide.active {
  opacity: 1;
  transition-duration: 1s;
}
#textCont {
  text-align: left;
}

@media (max-width: 1540px) {
  #photoContainer {
    overflow-y: auto;
    width: 100%;
  }
  #buttonContainer {
    padding: 5px;
    min-width: 150px;
    height: 45px;
  }
  #photoContainer::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@media (max-width: 1540px) {
  #photoContainer {
    overflow-y: auto;
    width: 100%;
  }
  #buttonContainer {
    padding: 5px;
    min-width: 150px;
    height: 45px;
  }
  #photoContainer::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@media (max-width: 1400px) {
  .innerContainer {
    width: 70%;
  }
  .close {
    right: 15%;
  }
  #connectButton {
    width: 40% !important;
  }
  #coverImage {
    width: 95% !important;
  }
  #header-nav-container {
    width: auto;
  }
}

@media (max-width: 1250px) {
  /* #buttonContainer {
    padding: 5px;
    min-width: 150px;
    height: 45px
  } */
}

@media (max-width: 1200px) {
  .cid-u8iUylQM2c img,
  .cid-u8iUylQM2c .item-img {
    height: 300px;
    object-fit: cover;
  }
  #event-container {
    flex-direction: column !important;
  }
  #event-wall {
    align-items: center !important;
    text-align: center !important;
  }
  #rightArrow {
    display: none !important;
  }
}

@media (max-width: 1000px) {
  .innerContainer {
    height: 50%;
  }
  .close {
    top: 20%;
  }
  #textCont {
    padding-right: 0 !important;
  }
  #partnerNewsContainer {
    min-height: 400px !important;
  }
  #connectButton {
    width: 50% !important;
  }
  #header_text_partner {
    font-size: 36px;
  }
  #faqContainer {
    width: 80% !important;
  }
  #nav-container {
    justify-content: center;
  }
  #welcome-container {
    padding-top: 60px;
  }
}
@media (max-width: 900px) {
  #title-text {
    align-items: center;
  }
  #footer-container {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  #footer-section-container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #social-container {
    margin-top: 0px;
  }
  #responsive-working-hours-container {
    margin-bottom: 0px !important;
  }
}

@media (max-width: 850px) {
  #nav-container {
    justify-content: space-between;
  }
  #quartly-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
  #donation-container {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  #donation-container2 {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
  }
  #tabs-container-profile {
    padding-left: 5px;
    padding-right: 5px;
    min-height: 30px;
    width: 50%;
  }
}

@media (max-width: 700px) {
  .innerContainer {
    width: 80%;
  }

  #faqContainer {
    width: 95% !important;
  }
  #iconLeft {
    margin-right: 0;
  }
  #iconRight {
    margin-left: 0;
  }
  #partnerNewsDivder {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #header_text_partner {
    text-align: center !important;
  }
  #textCont {
    text-align: center !important;
  }
  #leftSide {
    width: 100% !important;
  }
  #rightSide {
    width: 100% !important;
    align-items: center !important;
  }
  #realRightSide {
    width: 100% !important;
  }
  #coverImage {
    width: 80% !important;
    margin-top: 60px !important;
    margin-bottom: 140px;
  }
  #partnerNewsContainer {
    padding-top: 60px;
    position: relative;
  }
  #connectButton {
    position: absolute;
    bottom: 60px;
  }
  #backgrounImageNews {
    display: none;
  }
  #welcome-header-container {
    height: 500px;
  }
  #bio-container {
    padding: 15px;
    max-width: 100%;
    width: 85%;
  }
  #bio-container-content {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

}

.welcome-container {
  width: 100%;
  bottom: 100px;
}
.bannerImg {
  height: 40vh;
}

}

@media (max-width: 600px) {
  #loading_container {
    margin-top: 120px;
  }
  #buttonContainer {
    padding: 2px;
    min-width: 100px;
    height: 40px;
  }
  #bigcontainer {
    width: 90%;
  }

  #buttonTabs {
    font-size: 16px;
  }
  #photoContainer {
    gap: 10px;
  }
  #photo01 {
    display: none;
  }
  #photoCarsal {
    height: auto;
    position: relative;
    display: flex;
    width: 100% !important;
  }
  #carsalContainer {
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 40px;
    /* flex-direction: column; */
  }
  #imageCarsal {
    min-width: 90vw;
    min-height: 500px;
  }
  .innerContainer {
    width: 90%;
  }

  #iconLeft {
    position: absolute;
    left: 10px;
    z-index: 1000;
  }
  #iconRight {
    position: absolute;
    right: 10px;
    z-index: 1000;
  }

  #event-container {
    width: 80% !important;
  }
  #text-container {
    padding-right: 30px;
    padding-left: 20px;
    width: 100vw;
  }
  #outReach-container {
    width: 100vw;
  }
  #gps-team-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    width: 100vw;
  }
  #impact-container {
    display: flex;
    justify-content: center;
    width: 100vw;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20;
  }
  #name-container {
    flex-direction: column;
  }
  .name, .email {
    border: 1px solid rgba(145, 158, 171, 0.5);
  border-radius: 6px;
  position: relative;
  width: 90vw;
  margin: 12.5px;
  line-height: 5ex;
  color: black;
  }
  .phone-number, .input-message {
    width: 90vw;
  }
  #prayer {
    text-align: center !important;
    font-size: 34px;
  }
  #login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin-left: 0px;
    margin-right: 0px;
    padding: 10px;
  }

  #member-networking-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95vw;
     margin-left: 0px;
  }
  #networking-page-image {
    /* height: 500px; */
    background-size: contain;
  }
  #search-bar-networking {
    display: flex;
    flex-direction: column;
  }
  #header-networking-container {
    width: 95vw;
  }

  
}

@media (max-width: 450px) {
  #nav-container {
    justify-content: flex-end;
  }
  #welcome-container {
    padding-top: 120px;
    padding-left: 20px;
    padding-right: 20px;
  }
  #welcome-header-container {
    /* height: 400px; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 200px;
  }
}

@media (max-width: 400px) {
  .innerContainer {
    width: 100%;
    background-color: transparent;
  }
  .imageSlider {
    height: 100% !important;
    width: 100% !important;
  }
  #event-container {
    width: 100% !important;
  }
  #tabs-container-profile {
  width: 95%;
  }
  .bannerImg {
    height: 60vh;
  }
}
